import "@aws-amplify/ui-react/styles.css";
import "./auth.scss";

import { signInWithRedirect } from "@aws-amplify/auth";
import { Authenticator, Divider } from "@aws-amplify/ui-react";
import { Button } from "@aws-amplify/ui-react";
import _ from "lodash";
import { useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../auth";
import { PageTitle } from "../../../common/components/page-title";
import { CONFIG } from "../../../config";
import paths from "../../../paths";

const components = {
  SignIn: {},
};

if (!_.isEmpty(CONFIG.FEDERATED_IDENTITY_PROVIDER)) {
  _.set(components.SignIn, "Header", () => (
    <div style={{ padding: "2rem", paddingBottom: "0" }}>
      <Button
        onClick={() =>
          signInWithRedirect({
            provider: { custom: CONFIG.FEDERATED_IDENTITY_PROVIDER },
          })
        }
        isFullWidth
      >
        Sign in through AAD
      </Button>
      <Divider label="OR" style={{ marginTop: "2rem" }} size="small" />
    </div>
  ));
}

const states = {
  signIn: {
    title: "Sign in",
    footer: (
      <>
        {/* <div className="fs-7 fw-bold text-center pt-5">
          Don't have an account?{" "}
          <a href="https://research.csiro.au/easi/subscription-apply/">Apply</a>
        </div> */}
      </>
    ),
  },
  signUp: {
    title: "Sign up",
    footer: (
      <div className="fs-7 fw-bold text-center pt-5">
        Already have an account? <Link to={paths.SIGN_IN}>Sign in</Link>
      </div>
    ),
  },
  forgotPassword: { title: "Reset", footer: null },
};

interface AuthProps {
  defaultAuthedPage: string;
  initialState?: "signIn" | "signUp" | "forgotPassword";
}

export const Auth = ({
  defaultAuthedPage,
  initialState = "signIn",
}: AuthProps) => {
  const { authStatus, toSignIn, toSignUp, toForgotPassword } = useAuth();
  const location = useLocation();
  useEffect(() => {
    if (initialState === "signUp") {
      toSignUp();
    }
    if (initialState === "signIn") {
      toSignIn();
    }
    if (initialState === "forgotPassword") {
      toForgotPassword();
    }
  }, [initialState, toSignIn, toSignUp, toForgotPassword]);
  if (authStatus === "authenticated") {
    const to =
      _.get(location, "state.from.pathname", defaultAuthedPage) +
      _.get(location, "state.from.search", "");
    return <Navigate to={to} replace={true} />;
  }
  return (
    <>
      <PageTitle>{states[initialState].title}</PageTitle>
      <div className="container pt-3 easi-auth">
        <Authenticator
          initialState={initialState}
          signUpAttributes={["email", "name"]}
          loginMechanisms={initialState === "signUp" ? undefined : ["email"]}
          hideSignUp={initialState !== "signUp"}
          components={components}
        />
      </div>
      {states[initialState].footer}
    </>
  );
};
