import _ from "lodash";

import { AttributeId } from "../../constants";
import { DataControlsType } from "../../types";
import {
  generateCostsData,
  getGroupsFromData,
  prepareActivityData,
} from "../../utils/data";
import { generateSubscriptionBaseCosts } from "../../utils/generate-subscription-base-costs";
import { ActivityProps } from "./activity.component";
import { ActivityLoaderData } from "./activity-loader";

interface GenerateActivityPropsArgs {
  loaderData: ActivityLoaderData;
  dataControls: DataControlsType;
}

export const generateActivityProps = ({
  loaderData: { activity: data, datetimeRange, workspaces, workspaceCharges },
  dataControls,
}: GenerateActivityPropsArgs): Pick<
  ActivityProps,
  "costsData" | "groups" | "activityData" | "dataControls"
> => {
  const { intervalId, selectedAttributes } = dataControls.value;
  const activityData = prepareActivityData({
    data,
    datetimeRange,
    intervalId,
    selectedAttributes,
    workspaces,
  });
  const workspaceIds = selectedAttributes[AttributeId.Workspace]
    ? [_.get(selectedAttributes[AttributeId.Workspace], "id")]
    : _.uniq(_.map(workspaceCharges, "workspaceId"));
  const filteredSubscriptionPayments = _.filter(
    workspaceCharges,
    ({ workspaceId }) => _.includes(workspaceIds, workspaceId)
  );
  const subscriptionBaseCosts = generateSubscriptionBaseCosts({
    datetimeRange,
    intervalId,
    workspaceCharges: filteredSubscriptionPayments,
  });
  const costsData = generateCostsData({
    dataControlsValue: dataControls.value,
    activity: activityData,
    subscriptionBaseCosts,
  });
  const groups = getGroupsFromData(dataControls.value.grouping, activityData);
  return {
    costsData,
    groups,
    activityData,
    dataControls,
  };
};
