import { createContext, useContext } from "react";

import { AuthStatus } from "./constants";

interface CommonValue {
  toSignIn: () => void;
  toSignUp: () => void;
  toForgotPassword: () => void;
}

export interface AuthenticatedValue extends CommonValue {
  authStatus: AuthStatus.Authenticated;
  tokens: {
    accessToken: string;
    idToken: string;
  };
  signOut: () => void;
  hasRole: (role: unknown) => boolean;
  userId: string;
  isFederated: boolean;
}

interface NonAuthenticatedValue extends CommonValue {
  authStatus: Omit<AuthStatus, AuthStatus.Authenticated>;
}

export type AuthContextValue = AuthenticatedValue | NonAuthenticatedValue;

export const isAuthenticatedValue = (
  value: AuthContextValue
): value is AuthenticatedValue => value.authStatus === AuthStatus.Authenticated;

// Default values here are used when there is no provider, good for tests as you don't have to wrap with provider.
export const AuthContext = createContext<AuthContextValue>({
  authStatus: AuthStatus.Configuring,
  toSignIn: () => {},
  toSignUp: () => {},
  toForgotPassword: () => {},
});

export const useAuth = () => useContext(AuthContext);
