import _ from "lodash";
import { DateTime } from "luxon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

interface TimeAgoCellProps {
  iso?: string;
}

export const TimeAgoCell = ({ iso }: TimeAgoCellProps) => {
  if (!_.isString(iso)) {
    return "Unknown";
  }
  const datetime = DateTime.fromISO(iso);
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {datetime.toFormat("FF")}
        </Tooltip>
      )}
    >
      <span>{datetime.toRelative()}</span>
    </OverlayTrigger>
  );
};
