import _ from "lodash";
import * as Yup from "yup";

import { fetcher } from "../auth/fetcher";
import { UserFormSubmissionWithoutWorkspaces } from "../dashboard/components/user-form";
import { ApiMode } from "../dashboard/types";
import { PlanId, plans } from "../plans";
import { ApiUserFields, WorkspaceUserRole } from "./users";
import { apiUrl, transformKeysDeep } from "./utils";

export interface Customer {
  id: string;
  name: string;
  address: string;
  email: string;
}

export interface Workspace {
  workspaceId: string;
  code: string;
  label: string;
  tenancyName: string;
  planId: PlanId;
  status: string;
  numberOfSeatsIncluded: number | false;
  startDate?: string | null;
  endDate?: string | null;
  costcode?: number | null;
  o2d?: string | null;
  wbs?: string | null;
  customer?: Customer | null;
  createdAt: string | undefined;
}

type CreateWorkspace = Omit<Workspace, "workspaceId" | "status">;

export interface CreateWorkspaceWithOwner {
  workspace: CreateWorkspace;
  ownerId: string;
  ownerFields?: Omit<UserFormSubmissionWithoutWorkspaces, "userId">;
}

export interface WorkspaceWithUsers extends Workspace {
  users: {
    userId: string;
    role: WorkspaceUserRole;
  }[];
}

export type CreateWorkspacesRequest = {
  workspace_in: {
    plan_id: PlanId;
    label: string;
    code: string;
    tenancy_name: string;
    start_date: string | null;
    end_date: string | null;
    costcode: number | null;
    o2d: string | null;
    wbs: string | null;
  };
  owner_id: string;
  owner_in?: Partial<ApiUserFields>;
};

export type WorkspacesResponseItem = {
  workspace_id: string;
  plan_id: PlanId;
  label: string;
  code: string;
  tenancy_name: string;
  status: string;
  users: {
    user_id: string;
    role: WorkspaceUserRole;
  }[];
  start_date: string | null;
  end_date: string | null;
  costcode: number | null;
  o2d: string | null;
  wbs: string | null;
  customer: Customer | null;
  created_at: string | null;
};

export type WorkspacesResponseType = WorkspacesResponseItem[];

interface RequestArguments {
  signal: AbortSignal;
  mode?: ApiMode;
}

export const workspacesValidationSchema = Yup.array(
  Yup.object({
    label: Yup.string().required(),
    code: Yup.string().required(),
    workspace_id: Yup.string().required(),
  })
);

export interface ManualInvoiceRequestValue {
  dateRange: [string, string];
  receipientEmails: string[];
  isResendingEnabled: boolean;
}

interface SendManualInvoiceRequestArgs extends ManualInvoiceRequestValue {
  workspaceId: string;
}

const modes = {
  [ApiMode.User]: {
    // @TODO: These fieldsToOmitWhenUpdating could be addressed in form using _.pick, rather than here.
    fieldsToOmitWhenUpdating: ["workspace_id", "status"],
    patchUrl: "users/workspaces",
    getUrl: apiUrl("workspace-users"),
  },
  [ApiMode.Admin]: {
    fieldsToOmitWhenUpdating: ["workspace_id"],
    patchUrl: "workspaces",
    getUrl: apiUrl("workspaces/all"),
  },
};

export type WorkspaceApiMode = keyof typeof modes;

export const Workspaces = {
  prepareForApi(
    workspace: Workspace
  ): Omit<WorkspacesResponseItem, "users" | "created_at"> {
    return {
      workspace_id: workspace.workspaceId,
      plan_id: workspace.planId,
      label: workspace.label,
      tenancy_name: workspace.tenancyName,
      code: workspace.code,
      status: workspace.status,
      start_date: workspace.startDate || null, // @TODO: Have endpoint not require optional fields.
      end_date: workspace.endDate || null,
      costcode: workspace.costcode || null,
      o2d: workspace.o2d || null,
      wbs: workspace.wbs || null,
      customer: workspace.customer || null,
    };
  },
  parseFromApi(data: WorkspacesResponseType): WorkspaceWithUsers[] {
    return _.map(data, (workspace) => ({
      // @TODO: Could look at using _.snakeCase method in a loop over property names.
      workspaceId: workspace.workspace_id,
      planId: workspace.plan_id,
      label: workspace.label,
      code: workspace.code,
      tenancyName: workspace.tenancy_name,
      users: workspace.users.map((user) => ({
        userId: user.user_id,
        role: user.role,
      })),
      // @TODO: Maybe have plans as a separate API endpoint. Also keeping it separate would be nicer rather than nesting in workspace.
      numberOfSeatsIncluded: _.get(
        plans[workspace.plan_id],
        "numberOfSeatsIncluded",
        1
      ),
      status: workspace.status,
      startDate: workspace.start_date || undefined,
      endDate: workspace.end_date || undefined,
      costcode: workspace.costcode || undefined,
      o2d: workspace.o2d || undefined,
      wbs: workspace.wbs || undefined,
      customer: workspace.customer || undefined,
      createdAt: workspace.created_at || undefined,
    }));
  },
  async fetch(args: RequestArguments): Promise<WorkspacesResponseType> {
    const { signal, mode } = _.defaults({}, args, { mode: ApiMode.User });
    const response = await fetcher({ url: modes[mode].getUrl, signal });
    if (response.status !== 200) {
      // @TODO: Could handle this in the fetcher interceptors?
      // @TODO: Catch these 403s and redirect user to sign in. Keep in mind auth loop if actual 403 when authed.
      throw new Response(null, {
        status: 403,
        statusText: "Access to costs API denied.",
      });
    }
    workspacesValidationSchema.validateSync(response.data);
    return response.data;
  },
  async fetchAndParse(args: RequestArguments): Promise<WorkspaceWithUsers[]> {
    const data = await Workspaces.fetch(args);
    return Workspaces.parseFromApi(data);
  },
  create(values: CreateWorkspaceWithOwner) {
    const data: CreateWorkspacesRequest = {
      workspace_in: {
        plan_id: values.workspace.planId,
        label: values.workspace.label,
        tenancy_name: values.workspace.tenancyName,
        code: values.workspace.code,
        start_date: values.workspace.startDate || null, // @TODO: Have endpoint not require optional fields.
        end_date: values.workspace.endDate || null,
        costcode: values.workspace.costcode || null,
        o2d: values.workspace.o2d || null,
        wbs: values.workspace.wbs || null,
      },
      owner_id: values.ownerId,
    };
    if (!_.isUndefined(values.ownerFields)) {
      data.owner_in = transformKeysDeep(
        _.snakeCase,
        values.ownerFields
      ) as Partial<ApiUserFields>;
      if (!_.isUndefined(data.owner_in)) {
        data.owner_in.projects = _.zipObject(
          _.map(data.owner_in.projects, "key"),
          _.map(data.owner_in.projects, "value")
        );
      }
    }
    return fetcher({
      url: apiUrl("workspaces"),
      method: "POST",
      data,
    });
  },
  delete(values: Workspace) {
    return fetcher({
      url: apiUrl(`workspaces/${encodeURIComponent(values.workspaceId)}`),
      method: "DELETE",
    });
  },
  update(values: Workspace, mode: ApiMode) {
    const settings = modes[mode];
    return fetcher({
      url: apiUrl(
        `${settings.patchUrl}/${encodeURIComponent(values.workspaceId)}`
      ),
      method: "PATCH",
      data: _.omit(
        Workspaces.prepareForApi(values),
        settings.fieldsToOmitWhenUpdating
      ),
    });
  },
  async importCsv(request: Request) {
    return fetcher({
      url: apiUrl("workspaces/csv"),
      method: request.method,
      data: await request.formData(),
      headers: {
        "Content-Type": request.headers.get("Content-Type"),
      },
    });
  },
  sendManualInvoiceRequest(values: SendManualInvoiceRequestArgs) {
    return fetcher({
      url: apiUrl("workspaces/manual-invoice-request"),
      method: "POST",
      data: transformKeysDeep(_.snakeCase, values),
    });
  },
};
