import { Form, Formik, useField } from "formik";
import { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Form as BsForm,
  Modal,
  ModalDialogProps,
} from "react-bootstrap";
import * as Yup from "yup";

import { DateRangeInput } from "../../../activity/components/date-range-input";
import { ManualInvoiceRequestValue } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { TextArrayField } from "../text-array-field";

const schema = Yup.object({
  dateRange: Yup.array(
    Yup.string().required("Date range is required")
  ).required(),
  receipientEmails: Yup.array(
    Yup.string().email().required("Receipient emails required")
  )
    .min(1, "Must include at least one receipient")
    .required(),
  isResendingEnabled: Yup.bool().required(),
});

const Dialog = ({ children }: ModalDialogProps) => children;

const DateRangeField = () => {
  const [, meta, helpers] = useField("dateRange");
  return (
    <DateRangeInput
      label="Date range"
      onChange={helpers.setValue}
      value={meta.value}
    />
  );
};

interface ManualInvoiceRequestProps {
  children: ({ open }: { open: () => void }) => ReactNode;
  onSubmit: (values: ManualInvoiceRequestValue) => void;
  isSubmitting: boolean;
  initialValues: ManualInvoiceRequestValue;
  initialShow?: boolean;
}

export const ManualInvoiceRequest = ({
  children,
  onSubmit,
  isSubmitting,
  initialValues,
  initialShow = false,
}: ManualInvoiceRequestProps) => {
  const [show, setShow] = useState(initialShow);
  useEffect(() => {
    if (!isSubmitting) {
      setShow(false);
    }
  }, [isSubmitting]);
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} dialogAs={Dialog}>
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          <Form method="post" noValidate className="h-100">
            <Modal.Dialog scrollable>
              <Modal.Header closeButton>
                <Modal.Title>Manual invoice request</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <fieldset disabled={isSubmitting || !show}>
                  <DateRangeField />
                  <div className="mb-3">
                    <TextArrayField
                      title="Receipients"
                      name="receipientEmails"
                      label="Email"
                    />
                  </div>
                  <FormItem
                    className="mb-3"
                    name="isResendingEnabled"
                    label="Is resending enabled"
                    type="checkbox"
                    as={BsForm.Check}
                  />
                </fieldset>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting || !show}
                >
                  Ok
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </Form>
        </Formik>
      </Modal>
      {children({ open: () => setShow(true) })}
    </>
  );
};
