import { SpawnerGroups } from "../../../api";
import { createToast, ToastType } from "../../../common/components/toasts";
import { createErrorToast } from "../../../common/components/toasts/create-toast";

const processSpawnerGroupsAction = async ({
  request,
}: {
  request: Request;
}): Promise<ToastType[]> => {
  const toasts: ToastType[] = [];
  const values = await request.json();
  if (request.method === "POST") {
    await SpawnerGroups.create(values);
    toasts.push(
      createToast({
        header: "Successfully added spawner group",
        body: `Spawner group with with ID ${values.spawnerGroupId} has been added.`,
      })
    );
  } else if (request.method === "DELETE") {
    await SpawnerGroups.delete(values);
    toasts.push(
      createToast({
        header: "Successfully removed spawner group",
        body: `Spawner group with with ID ${values.spawnerGroupId} has been removed.`,
      })
    );
  } else if (request.method === "PATCH") {
    await SpawnerGroups.update(values);
    toasts.push(
      createToast({
        header: "Successfully updated spawner group",
        body: `Spawner group with with ID ${values.spawnerGroupId} has been updated.`,
      })
    );
  }
  return toasts;
};

export const spawnerGroupsAction = async ({
  request,
}: {
  request: Request;
}) => {
  const toasts: ToastType[] = [];
  try {
    toasts.push(...(await processSpawnerGroupsAction({ request })));
  } catch (error) {
    if (error instanceof Error) {
      toasts.push(createErrorToast(error));
    } else {
      throw error;
    }
  }
  return { toasts };
};
