import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useState } from "react";
import { Modal } from "react-bootstrap";

import { CreateWorkspaceWithOwner } from "../../../api";
import { TabComponentMode } from "../../types";
import { UserForm, UserFormSubmissionWithoutWorkspaces } from "../user-form";
import { UserIdForm } from "../user-id-form";
import {
  OutputAdmin as WorkspaceSubmission,
  WorkspaceForm,
} from "../workspace-form";
import { Step } from "./step";

interface AddWorkspaceModalProps {
  show: boolean;
  initialStep?: Step;
  userIds: string[];
  initialOwnerId?: string;
  onSubmit: (value: CreateWorkspaceWithOwner) => void;
  onHide: () => void;
}

export const AddWorkspaceModal = ({
  show,
  userIds,
  initialStep = Step.Workspace,
  initialOwnerId = "",
  onSubmit,
  onHide,
}: AddWorkspaceModalProps) => {
  const [step, setStep] = useState<Step>(initialStep);
  const [ownerId, setOwnerId] = useState(initialOwnerId);
  // @TODO: Type WorkspaceSubmission and CreateWorkspaceWithOwner["workspace"] should be the same.
  const [workspace, setWorkspace] = useState<WorkspaceSubmission>();
  const [ownerFields, setOwnerFields] =
    useState<UserFormSubmissionWithoutWorkspaces>();
  const handleSubmit = (value: CreateWorkspaceWithOwner) => {
    onSubmit(value);
    setStep(Step.Creating);
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => setStep(initialStep)}
      size="xl"
    >
      {step === Step.Workspace && (
        <WorkspaceForm
          onSubmit={(value) => {
            setWorkspace(value);
            setStep(Step.OwnerId);
          }}
          mode={TabComponentMode.Admin}
          submitButtonText="Next"
        >
          {({ fields, submit }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Configure workspace</Modal.Title>
              </Modal.Header>
              <Modal.Body>{fields}</Modal.Body>
              <Modal.Footer>{submit}</Modal.Footer>
            </>
          )}
        </WorkspaceForm>
      )}
      {step === Step.OwnerId && (
        <>
          <Modal.Body>
            <UserIdForm
              userId={ownerId}
              userIds={userIds}
              submitButtonText="Next"
              onSubmit={(value) => {
                setOwnerId(value);
                const isExistingUser = _.includes(userIds, value);
                if (isExistingUser) {
                  handleSubmit({
                    workspace:
                      workspace as CreateWorkspaceWithOwner["workspace"],
                    ownerId: value,
                  });
                } else {
                  setStep(Step.OwnerFields);
                }
              }}
            >
              {({ fields, submit }) => (
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>Assign an owner</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{fields}</Modal.Body>
                  <Modal.Footer>{submit}</Modal.Footer>
                </>
              )}
            </UserIdForm>
          </Modal.Body>
        </>
      )}
      {step === Step.OwnerFields && (
        <>
          <Modal.Body>
            <UserForm
              user={{ ...ownerFields, userId: ownerId }}
              onSubmit={(value) => {
                setOwnerFields(value);
                handleSubmit({
                  workspace: workspace as CreateWorkspaceWithOwner["workspace"],
                  ownerId,
                  ownerFields: value,
                });
                setStep(Step.Creating);
              }}
              isSubmitEnabledWithoutChange
              submitButtonText="Next"
            >
              {({ fields, submit }) => (
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>Configure owner</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{fields}</Modal.Body>
                  <Modal.Footer>{submit}</Modal.Footer>
                </>
              )}
            </UserForm>
          </Modal.Body>
        </>
      )}
      {step === Step.Creating && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Creating workspace</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center py-5">
              Submitting{" "}
              <FontAwesomeIcon className="text-primary" icon={faSpinner} spin />
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};
