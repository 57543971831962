import _ from "lodash";

import {
  Activity,
  MonthlyCosts,
  Workspaces,
  WorkspaceWithUsers,
} from "../../../api";
import { ApiMode } from "../../types";

export interface WorkspaceCost {
  wbs: string;
  workspaceId: string;
  label: string;
  status: string;
  memberCount: number;
  totalCost: string;
}

export interface GenerateCostsReportInput {
  workspaces: WorkspaceWithUsers[];
  costs: MonthlyCosts;
}

export const formatUsd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "code",
}).format;

export const generateCostsReportData = (
  input: GenerateCostsReportInput
): WorkspaceCost[] => {
  const costsByCode = _.fromPairs(
    _.map(input.costs, (item) => [
      item.code,
      {
        wbs: item.code,
        totalCost: formatUsd(item.cost),
      },
    ])
  );
  const workspacesByCode = _.fromPairs(
    _.map(input.workspaces, (item) => [
      item.code,
      {
        workspaceId: item.workspaceId,
        label: item.label,
        wbs: item.code,
        status: item.status,
        memberCount: item.users.length,
      },
    ])
  );
  // @TODO: Remove '_.without' '_UNALLOCATED_' when it returns sensible value.
  const codes = _.without(
    _.uniq([..._.keys(costsByCode), ..._.keys(workspacesByCode)]),
    "_UNALLOCATED_"
  );
  return _.sortBy(
    _.map(codes, (code) =>
      _.defaults({}, costsByCode[code], workspacesByCode[code], {
        workspaceId: "-",
        label: "-",
        status: "No matching workspace",
        memberCount: 0,
        totalCost: formatUsd(0),
      })
    ),
    ({ workspaceId }) => _.toNumber(workspaceId)
  );
};
export interface ReportsActionData {
  workspacesCost?: WorkspaceCost[];
}

export const reportsAction = async ({
  request,
}: {
  request: Request;
}): Promise<ReportsActionData> => {
  const workspaces = await Workspaces.fetchAndParse({
    signal: request.signal,
    mode: ApiMode.Admin,
  });
  const { dateRange } = await request.json();
  const costs = await Activity.fetchAndParseReportData({
    datetimeRange: dateRange,
    signal: request.signal,
  });
  return { workspacesCost: generateCostsReportData({ workspaces, costs }) };
};
