import { signOut } from "@aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import _ from "lodash";
import { useEffect, useState } from "react";

import { AuthStatus } from "./constants";
import { AuthContextValue } from "./context";
import { generateAuthenticatedValue } from "./generate-authenticated-value";
import { getAuthTokensFromCookie } from "./get-auth-tokens-from-cookie";
import { getAuthTokensFromSession } from "./get-auth-tokens-from-session";
import { listeners } from "./listeners";

export const useAuthProvider = (authTokensCookieKey: string) => {
  const { authStatus, toSignIn, toSignUp, toForgotPassword } = useAuthenticator(
    (context) => [context.authStatus]
  );
  const [value, setValue] = useState<AuthContextValue>({
    authStatus,
    toSignUp,
    toSignIn,
    toForgotPassword,
  });
  useEffect(() => {
    (async () => {
      const setValueAndNotifyListeners = (newValue: AuthContextValue) => {
        _.over(listeners)(newValue);
        setValue(newValue);
      };
      const authTokensFromCookie = getAuthTokensFromCookie(authTokensCookieKey);
      if (authTokensFromCookie) {
        setValueAndNotifyListeners(
          generateAuthenticatedValue(
            signOut,
            authTokensFromCookie,
            toSignUp,
            toSignIn,
            toForgotPassword
          )
        );
      } else if (authStatus === AuthStatus.Authenticated) {
        const tokens = await getAuthTokensFromSession();
        setValueAndNotifyListeners(
          generateAuthenticatedValue(
            signOut,
            tokens,
            toSignUp,
            toSignIn,
            toForgotPassword
          )
        );
      } else if (value.authStatus !== authStatus) {
        setValueAndNotifyListeners({
          authStatus,
          toSignUp,
          toSignIn,
          toForgotPassword,
        });
      }
    })();
  }, [
    authStatus,
    toSignUp,
    toSignIn,
    toForgotPassword,
    authTokensCookieKey,
    value.authStatus,
  ]);
  return value;
};
