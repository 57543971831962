import _ from "lodash";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import { WorkspaceCharge } from "../../../api";
import { ReportPeriodId, reportPeriods, Scopes } from "../../report-periods";
import { ActivityData } from "../../types";
import { activityFetcher } from "../activity/activity-loader";

export interface ReportLoaderData {
  activity: ActivityData[];
  scopes: Scopes;
  title: string;
  workspaceCharges: WorkspaceCharge[];
}

interface ReportFetchArgs {
  periodId: ReportPeriodId;
  start: string;
  signal: AbortSignal;
  isAdminMode: boolean;
}

export const reportFetcher = async ({
  periodId,
  start,
  signal,
  isAdminMode,
}: ReportFetchArgs): Promise<ReportLoaderData> => {
  const period = reportPeriods[periodId];
  const scopes = period.getScopes(start);
  const datetimeRange = scopes.outer.datetimeRange;
  const { activity, workspaceCharges } = await activityFetcher({
    datetimeRange,
    signal,
    isAdminMode,
  });
  const title = `${period.label} costs report`;
  return {
    activity,
    scopes,
    title,
    workspaceCharges,
  };
};

export const reportLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const start = url.searchParams.get("start");
  const periodId = url.searchParams.get("period-id") as ReportPeriodId;
  const mode = url.searchParams.get("mode");
  if (_.isNull(start) || _.isNull(periodId)) {
    throw new Error("No start and or period-id provided in query string");
  }
  return defer({
    report: reportFetcher({
      periodId,
      start,
      signal: request.signal,
      isAdminMode: mode === "admin",
    }),
  });
};
