import { Amplify } from "aws-amplify";

import { CONFIG } from "../config";
export { listen } from "./listeners";
export { isAuthenticatedValue, useAuth } from "./context";
export { AuthProvider } from "./auth-provider";
export { fetcher } from "./fetcher";
export { RequireAuth } from "./require-auth";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: CONFIG.USER_POOL_CLIENT_ID,
      userPoolId: CONFIG.USER_POOL_ID,
      signUpVerificationMethod: "code",
      loginWith: {
        oauth: {
          domain: `${CONFIG.USER_POOL_DOMAIN}.auth.${CONFIG.REGION}.amazoncognito.com`,
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          // @TODO: Replace with the following callback URL [`${window.location.origin}/sign-in`], for federated IDP redirect?
          redirectSignIn: [window.location.origin],
          redirectSignOut: [],
          responseType: "code",
        },
      },
    },
  },
});

/*
@TODO: Could look at writing custom storage.
https://docs.amplify.aws/react/build-a-backend/auth/concepts/tokens-and-credentials/
cognitoUserPoolsTokenProvider.setKeyValueStorage(...);

Unable to remove old cookies if they were set with different 'neededAttributes'.

https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-remove-all-cookies

Object.keys(Cookies.get()).forEach(function(cookieName) {
  var neededAttributes = {
    // Here you pass the same attributes that were used when the cookie was created
    // and are required when removing the cookie
  };
  Cookies.remove(cookieName, neededAttributes);
});

Solution could be to create new cookie names.
*/
