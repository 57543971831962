import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useState } from "react";
import { Button, Form, InputGroup, ListGroup } from "react-bootstrap";

import { Attribute } from "../../attributes";
import { Dropdown } from "../dropdown";
import { filterOptions } from "./filter-options";

export type Options = { label: string; value: string; disabled?: boolean }[];

interface AttributeSelectorProps {
  attribute: Attribute;
  value?: string;
  onChange: (value: string | undefined) => void;
  options: Options;
  disabled?: boolean;
}

export const AttributeSelector = ({
  attribute,
  value,
  onChange,
  options,
  disabled = false,
}: AttributeSelectorProps) => {
  const [term, setTerm] = useState("");
  const filtered = filterOptions(options, term);
  return (
    <Dropdown
      label={_.startCase(attribute.singular)}
      text={_.get(_.find(filtered, { value }), "label", "All")}
      icon={attribute.icon as IconProp}
      onExited={() => setTerm("")}
      id={attribute.id}
      disabled={disabled}
    >
      {({ close }) => (
        <div className="p-3" style={{ width: "300px" }}>
          <div className="d-flex justify-content-between mb-3">
            <div className="fs-4 fw-bold">{_.startCase(attribute.plural)}</div>
            <Button
              variant="link"
              className="p-0 mb-3 text-primary"
              onClick={() => {
                onChange(undefined);
                close();
              }}
              disabled={_.isUndefined(value)}
            >
              Clear
            </Button>
          </div>
          <InputGroup className="mb-3 p-0 bg-body-secondary">
            <InputGroup.Text className="py-0 bg-transparent border-0">
              <FontAwesomeIcon size="sm" icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <Form.Control
              className="p-0 border-0 bg-transparent"
              placeholder={`Search ${attribute.plural}`}
              aria-label={attribute.singular}
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              size="sm"
            />
          </InputGroup>
          <ListGroup className="overflow-auto" style={{ height: 300 }}>
            {_.map(filtered, (option) => (
              <ListGroup.Item
                action
                onClick={() => {
                  onChange(option.value);
                  close();
                }}
                active={option.value === value}
                key={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
    </Dropdown>
  );
};
