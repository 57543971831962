import { ErrorMessage, Form, Formik } from "formik";
import _ from "lodash";
import { ReactNode } from "react";
import { Button, Col, Form as BsForm, Row } from "react-bootstrap";
import * as Yup from "yup";

import { Workspace } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { PlanId, plans } from "../../../plans";
import { TabComponentMode } from "../../types";
import { generateSubmitValue } from "./generate-submit-value";
import { fields } from "./workspace-fields";

type Input = Partial<Workspace>;

interface OutputUser {
  label: string;
}

export interface OutputAdmin extends OutputUser {
  code: string;
  tenancyName: string;
  planId: PlanId;
  startDate: string | null;
  endDate: string | null;
  costcode: number | null;
  o2d: string | null;
  wbs: string | null;
}

type OutputByMode<T extends TabComponentMode> = T extends TabComponentMode.Admin
  ? OutputAdmin
  : OutputUser;

interface WorkspaceFormProps<T extends TabComponentMode> {
  onSubmit: (values: OutputByMode<T>) => void;
  isSubmitting?: boolean;
  mode?: T;
  workspace?: Input;
  children: ({
    fields,
    submit,
  }: {
    fields: ReactNode;
    submit: ReactNode;
  }) => ReactNode;
  readOnly?: boolean;
  submitButtonText?: string;
}

const settingsByMode: {
  [mode in TabComponentMode]: {
    writableFieldIds: (keyof typeof fields)[];
  };
} = {
  [TabComponentMode.User]: {
    writableFieldIds: [
      "label",
      "startDate",
      "endDate",
      "costcode",
      "o2d",
      "wbs",
    ],
  },
  [TabComponentMode.Admin]: {
    writableFieldIds: [
      "label",
      "customer",
      "planId",
      "code",
      "tenancyName",
      "startDate",
      "endDate",
      "costcode",
      "o2d",
      "wbs",
    ],
  },
};

export const WorkspaceForm = <T extends TabComponentMode>({
  onSubmit,
  workspace,
  isSubmitting = false,
  mode = TabComponentMode.User as T,
  children,
  readOnly = false,
  submitButtonText = "Ok",
}: WorkspaceFormProps<T>) => {
  const settings = settingsByMode[mode];
  const initialValues = _.defaults(
    {},
    workspace,
    _.mapValues(fields, "default")
  );
  const isFieldReadOnly = (id: string) =>
    readOnly || !_.includes(settings.writableFieldIds, id);
  return (
    <Formik
      validationSchema={Yup.object(
        _.zipObject(
          settings.writableFieldIds,
          _.map(settings.writableFieldIds, (id) => fields[id].validation)
        )
      )}
      onSubmit={(values) => {
        const valueToSubmit = generateSubmitValue(
          workspace,
          _.pick(values, settings.writableFieldIds)
        ) as unknown as OutputByMode<T>;
        if (_.some(_.get(values, "customer", {}), _.isEmpty)) {
          _.set(valueToSubmit, "customer", null);
        }
        onSubmit(valueToSubmit);
      }}
      initialValues={initialValues}
    >
      {({ values, submitForm, errors }) => (
        <Form method="post" noValidate>
          <fieldset disabled={isSubmitting}>
            {children({
              fields: (
                <>
                  <Row>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="workspaceId"
                        label="ID"
                        type="text"
                        placeholder="Auto generated ID"
                        as={BsForm.Control}
                        readOnly
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="tenancyName"
                        label="Tenancy name"
                        type="text"
                        placeholder="tenancy-name"
                        as={BsForm.Control}
                        readOnly={
                          !_.isEmpty(_.get(workspace, "tenancyName")) ||
                          isFieldReadOnly("tenancyName")
                        }
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="code"
                        label="Code"
                        placeholder="Code"
                        type="text"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("code")}
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="planId"
                        label="Plan"
                        as={BsForm.Select}
                        disabled={
                          !_.includes(settings.writableFieldIds, "planId") ||
                          isFieldReadOnly("planId")
                        }
                      >
                        {_.map(plans, (option) => (
                          <option key={option.id} value={option.id}>
                            {option.label}
                          </option>
                        ))}
                      </FormItem>
                    </Col>
                    <Col lg="12">
                      <FormItem
                        className="mb-3"
                        name="label"
                        label="Label"
                        placeholder="Label"
                        type="text"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("label")}
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="costcode"
                        label="Costcode"
                        type="number"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("costcode")}
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="o2d"
                        label="O2D"
                        type="text"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("o2d")}
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="wbs"
                        label="WBS"
                        type="text"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("wbs")}
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="startDate"
                        label="Start date"
                        type="date"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("startDate")}
                      />
                    </Col>
                    <Col lg="4">
                      <FormItem
                        className="mb-3"
                        name="endDate"
                        label="End date"
                        type="date"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("endDate")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormItem
                        className="mb-3"
                        name="customer.id"
                        label="Customer ID"
                        placeholder="SAP customer ID"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("customer")}
                      />
                    </Col>
                    <Col lg="6">
                      <FormItem
                        className="mb-3"
                        name="customer.name"
                        label="Customer name"
                        placeholder="Enter name"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("customer")}
                      />
                    </Col>
                    <Col lg="6">
                      <FormItem
                        className="mb-3"
                        name="customer.address"
                        label="Customer address"
                        placeholder="address"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("customer")}
                      />
                    </Col>
                    <Col lg="6">
                      <FormItem
                        className="mb-3"
                        name="customer.email"
                        label="Billing email"
                        type="email"
                        placeholder="email"
                        as={BsForm.Control}
                        readOnly={isFieldReadOnly("customer")}
                      />
                    </Col>
                  </Row>
                  {_.has(errors, "customer") && _.isString(errors.customer) && (
                    <div className="text-danger mb-3">
                      <ErrorMessage name="customer" />
                    </div>
                  )}
                </>
              ),
              submit: readOnly ? null : (
                <Button
                  variant="primary"
                  onClick={submitForm}
                  disabled={
                    !_.isUndefined(workspace) &&
                    _.isEqual(
                      _.pick(values, settings.writableFieldIds),
                      _.pick(initialValues, settings.writableFieldIds)
                    )
                  }
                >
                  {submitButtonText}
                </Button>
              ),
            })}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
