import _ from "lodash";
import { DateTime } from "luxon";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import {
  Activity,
  WorkspaceCharge,
  WorkspaceCharges,
  Workspaces,
} from "../../../api";
import { CONFIG } from "../../../config";
import { ApiMode } from "../../../dashboard/types";
import { TIMEZONE_API } from "../../constants";
import { ActivityData, DatetimeRange, Workspace } from "../../types";

export interface ActivityLoaderData {
  activity: ActivityData[];
  isAdminMode: boolean;
  datetimeRange: DatetimeRange;
  workspaces: Workspace[];
  workspaceCharges: WorkspaceCharge[];
}

const modes = {
  [ApiMode.User]: {
    includeAllWorkspaces: false,
  },
  [ApiMode.Admin]: {
    includeAllWorkspaces: true,
  },
};

interface ActivityFetcherArgs {
  datetimeRange: DatetimeRange;
  signal: AbortSignal;
  isAdminMode: boolean;
}

export const activityFetcher = async ({
  datetimeRange,
  signal,
  isAdminMode,
}: ActivityFetcherArgs): Promise<ActivityLoaderData> => {
  const mode = isAdminMode ? ApiMode.Admin : ApiMode.User;
  const settings = modes[mode];
  const params: Parameters<typeof Activity.fetch>[0] = {
    datetimeRange,
    signal,
  };
  if (settings.includeAllWorkspaces) {
    params.allocationId = "_ALL_ALLOCATIONS_";
  }
  const [workspaces, activityResponse, workspaceCharges] = await Promise.all([
    Workspaces.fetchAndParse({ signal, mode }),
    Activity.fetch(params),
    CONFIG.IS_WORKSPACE_CHARGES_IN_ACTIVITY_ENABLED
      ? WorkspaceCharges.fetchAndParse({ signal })
      : new Promise<WorkspaceCharge[]>((resolve) => resolve([])),
  ]);
  const activity = Activity.parseFromApi(activityResponse, workspaces);
  return {
    activity,
    isAdminMode,
    datetimeRange,
    workspaces,
    workspaceCharges,
  };
};

const now = DateTime.now().setZone(TIMEZONE_API);

const initialDatetimeRange: DatetimeRange = [
  now.minus({ months: 1 }).plus({ days: 1 }).startOf("day").toISO() as string,
  now.endOf("day").toISO() as string,
];

export const activityLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const start = url.searchParams.get("start");
  const end = url.searchParams.get("end");
  const mode = url.searchParams.get("mode");
  let datetimeRange = initialDatetimeRange;
  if (_.isString(start) || _.isString(end)) {
    datetimeRange = [start, end] as DatetimeRange;
  }
  return defer({
    activity: activityFetcher({
      datetimeRange,
      signal: request.signal,
      isAdminMode: mode === "admin",
    }),
  });
};
