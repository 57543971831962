import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { isAuthenticatedValue, useAuth } from "../../../auth";
import { Spinner } from "../../../common/components/spinner";
import { getAttributeOptions } from "../../utils/attribute-options";
import { Activity } from "./activity.component";
import { ActivityLoaderData } from "./activity-loader";
import { generateActivityProps } from "./generate-activity-props";
import { useDataControls } from "./use-data-controls";

export const ConnectedActivityInner = (loaderData: ActivityLoaderData) => {
  const attributeOptions = getAttributeOptions({
    data: loaderData.activity,
    workspaces: loaderData.workspaces,
  });
  const dataControls = useDataControls({
    isAdminMode: loaderData.isAdminMode,
    datetimeRange: loaderData.datetimeRange,
    attributeOptions,
  });
  const auth = useAuth();
  if (!isAuthenticatedValue(auth)) {
    throw new Error("Not authed");
  }
  const showAdminModeSwitch =
    auth.hasRole("internal-group") && auth.hasRole("easi-admin");
  const activityProps = generateActivityProps({ loaderData, dataControls });
  return (
    <Activity
      {...activityProps}
      attributeOptions={attributeOptions}
      showAdminModeSwitch={showAdminModeSwitch}
    />
  );
};

export const ConnectedActivity = () => {
  const data = useLoaderData() as { activity: Promise<ActivityLoaderData> };
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.activity}>
        {(props: ActivityLoaderData) => <ConnectedActivityInner {...props} />}
      </Await>
    </React.Suspense>
  );
};
