import _ from "lodash";
import * as Yup from "yup";

import { PlanId, plans } from "../../../plans";

const planIdsRequiringCustomer = _.map(
  _.filter(plans, "requiresCustomer"),
  "id"
);

export const fields: {
  [id: string]: { default: unknown; validation: Yup.Schema };
} = {
  workspaceId: { default: "", validation: Yup.string() },
  planId: {
    default: PlanId.Subscription,
    validation: Yup.string()
      .oneOf(_.values(PlanId))
      .required("Plan is required"),
  },
  label: {
    default: "",
    validation: Yup.string().required("Label is required"),
  },
  code: { default: "", validation: Yup.string().required("Code is required") },
  tenancyName: {
    default: "",
    validation: Yup.string()
      .matches(
        /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/,
        'Tenancy name needs to match regex "[a-z0-9]([-a-z0-9]*[a-z0-9])?" (lowercase alphanumeric)'
      )
      .required(),
  },
  startDate: { default: "", validation: Yup.string() },
  endDate: { default: "", validation: Yup.string() },
  costcode: { default: "", validation: Yup.number() },
  o2d: { default: "", validation: Yup.string() },
  wbs: { default: "", validation: Yup.string() },
  customer: {
    default: {
      id: "",
      name: "",
      address: "",
      email: "",
    },
    validation: Yup.object({})
      .when("planId", {
        is: (planId: string) => _.includes(planIdsRequiringCustomer, planId),
        then: (schema) =>
          schema.shape({
            email: Yup.string()
              .email()
              .lowercase("User ID needs to be lowercase")
              .strict()
              .required(),
            name: Yup.string().required(),
            address: Yup.string().required(),
            id: Yup.string().required(),
          }),
      })
      .when("planId", {
        is: (planId: string) => !_.includes(planIdsRequiringCustomer, planId),
        then: (schema) =>
          schema
            .shape({
              email: Yup.string()
                .email()
                .lowercase("User ID needs to be lowercase")
                .strict(),
              name: Yup.string(),
              address: Yup.string(),
              id: Yup.string(),
            })
            .test(
              "all-or-none",
              "Either all fields must be filled or none",
              (value, { createError }) => {
                const fields = ["id", "name", "address", "email"];
                const setFields = _.reject(fields, (field) =>
                  _.isEmpty(_.get(value, field))
                );
                if (setFields.length > 0 && setFields.length < fields.length) {
                  return createError({
                    path: "customer",
                    message:
                      "Either all customer fields must be filled or none",
                  });
                }
                return true;
              }
            ),
      }),
  },
};
