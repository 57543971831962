import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import _ from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Alert, Button, Stack } from "react-bootstrap";
import { Link, SubmitFunction } from "react-router-dom";

import {
  FileDownloader,
  StatusError,
} from "../../../activity/components/file-downloader";
import {
  apiUrl,
  WorkspaceUserRole,
  WorkspaceUserWithUserFields,
  WorkspaceWithUsersWithFields,
} from "../../../api";
import paths from "../../../paths";
import { TabComponentMode } from "../../types";
import { ConfirmerModal } from "../confirmer-modal";
import { CsvImport } from "../csv-import";
import { ManualInvoiceRequest } from "../manual-invoice-request";
import { WorkspaceForm } from "../workspace-form";
import { WorkspaceUserModal } from "../workspace-user-modal";
import { WorkspaceUsersList } from "../workspace-users-list";
import { generateSeatsText } from "./generate-seats-text";

const lastMonthDateRange: [string, string] = [
  DateTime.now().minus({ months: 1 }).startOf("month").toISODate(),
  DateTime.now().minus({ months: 1 }).endOf("month").toISODate(),
];

interface WorkspaceProps {
  workspace: WorkspaceWithUsersWithFields;
  isSubmitting?: boolean;
  onSubmit: SubmitFunction;
  authedUserId?: string;
  mode?: TabComponentMode;
  userIds?: string[];
}

interface ModalState {
  show: boolean;
  title?: string;
  user?: Pick<WorkspaceUserWithUserFields, "userId" | "role">;
  method?: "post" | "patch";
}

const settingsByMode = {
  [TabComponentMode.User]: {
    isAdmin: false,
    hasCsvButtons: false,
    workspacesPath: paths.DASHBOARD_WORKSPACES,
    showCode: false,
    allowDelete: false,
    allowStatusChange: false,
    allowEdit: false,
    allowSendingInvoice: false,
    showActions: false,
  },
  [TabComponentMode.Admin]: {
    isAdmin: true,
    hasCsvButtons: true,
    workspacesPath: paths.DASHBOARD_ADMIN_WORKSPACES,
    showCode: true,
    allowDelete: true,
    allowStatusChange: true,
    allowEdit: true,
    allowSendingInvoice: true,
    showActions: true,
  },
};

export const Workspace = ({
  authedUserId,
  workspace,
  isSubmitting = false,
  onSubmit,
  userIds = [],
  mode = TabComponentMode.User,
}: WorkspaceProps) => {
  const settings = settingsByMode[mode];
  const [modal, setModal] = useState<ModalState>({ show: false });
  useEffect(() => {
    if (!isSubmitting) {
      setModal({ show: false });
    }
  }, [isSubmitting]);
  const role = _.get(_.find(workspace.users, { userId: authedUserId }), "role");
  const isAdmin =
    settings.isAdmin ||
    _.includes([WorkspaceUserRole.Admin, WorkspaceUserRole.Owner], role);
  const isOwner = role === WorkspaceUserRole.Owner;
  const numberOfSeatsIncluded = workspace.numberOfSeatsIncluded;
  const isConfirmRequired =
    _.isNumber(numberOfSeatsIncluded) &&
    workspace.users.length >= numberOfSeatsIncluded;
  const canEdit = settings.allowEdit || isOwner;
  const hasCustomer =
    _.isObject(workspace.customer) &&
    !_.some(_.values(workspace.customer), _.isEmpty);
  return (
    <>
      <nav aria-label="breadcrumb" className="mb-5">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={settings.workspacesPath}>Workspaces</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {workspace.label}
          </li>
        </ol>
      </nav>
      <div className="d-flex justify-content-between mb-5">
        <div>
          <FontAwesomeIcon
            className={clsx("me-2", {
              "text-danger": workspace.status === "Disabled",
              "text-success": workspace.status === "Enabled",
            })}
            icon={faCircle}
          />
          {workspace.status}
        </div>
        <div>
          {(settings.allowStatusChange ||
            settings.allowDelete ||
            settings.allowSendingInvoice) && (
            <Stack gap={3} direction="horizontal">
              <ManualInvoiceRequest
                onSubmit={(value) =>
                  onSubmit(
                    {
                      entity: "invoice",
                      workspaceId: workspace.workspaceId,
                      ...value,
                    },
                    {
                      method: "post",
                      encType: "application/json",
                    }
                  )
                }
                isSubmitting={isSubmitting}
                initialValues={{
                  dateRange: lastMonthDateRange,
                  receipientEmails: [],
                  isResendingEnabled: false,
                }}
              >
                {({ open }) => (
                  <div
                    title={
                      hasCustomer
                        ? "Send invoice request"
                        : "Need to set all customer fields to send invoice request"
                    }
                  >
                    <Button
                      disabled={isSubmitting || !hasCustomer}
                      onClick={open}
                    >
                      Send invoice
                    </Button>
                  </div>
                )}
              </ManualInvoiceRequest>
              {settings.allowStatusChange && (
                <>
                  {workspace.status === "Disabled" && (
                    <Button
                      disabled={isSubmitting}
                      onClick={() =>
                        onSubmit(
                          {
                            entity: "workspace",
                            workspaceId: workspace.workspaceId,
                            status: "Enabled",
                          },
                          {
                            method: "patch",
                            encType: "application/json",
                          }
                        )
                      }
                    >
                      Enable
                    </Button>
                  )}
                  {workspace.status === "Enabled" && (
                    <Button
                      disabled={isSubmitting}
                      onClick={() =>
                        onSubmit(
                          {
                            entity: "workspace",
                            workspaceId: workspace.workspaceId,
                            status: "Disabled",
                          },
                          {
                            method: "patch",
                            encType: "application/json",
                          }
                        )
                      }
                    >
                      Disable
                    </Button>
                  )}
                </>
              )}
              {settings.allowDelete &&
                workspace.status === "Disabled" &&
                _.get(workspace, "users", []).length === 0 && (
                  <ConfirmerModal
                    title="Remove workspace"
                    body="Are you sure you want to remove workspace?"
                    isSubmitting={isSubmitting}
                    onConfirm={() => {
                      onSubmit(
                        {
                          workspaceId: workspace.workspaceId,
                        },
                        {
                          method: "delete",
                          encType: "application/json",
                          action: settings.workspacesPath,
                        }
                      );
                    }}
                  >
                    {({ open }) => (
                      <Button disabled={isSubmitting} onClick={open}>
                        Delete
                      </Button>
                    )}
                  </ConfirmerModal>
                )}
            </Stack>
          )}
        </div>
      </div>
      <div className="my-5">
        <h2 className="mb-0">Details</h2>
        <hr />
        <WorkspaceForm
          readOnly={!canEdit}
          isSubmitting={isSubmitting}
          onSubmit={(values) =>
            onSubmit(
              {
                workspaceId: workspace.workspaceId,
                ...values,
                entity: "workspace",
              },
              {
                method: "patch",
                encType: "application/json",
              }
            )
          }
          workspace={workspace}
          mode={mode}
          submitButtonText="Save"
        >
          {({ fields, submit }) => (
            <>
              {fields}
              <div className="mt-3">{submit}</div>
            </>
          )}
        </WorkspaceForm>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <h2 className="mb-0">Users</h2>
        <div>
          {generateSeatsText({
            numberOfSeatsIncluded,
            numberOfSeatsInUse: workspace.users.length,
          })}
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-start mb-5">
        {isAdmin && (
          <Stack direction="horizontal" gap={3}>
            {isConfirmRequired ? (
              <ConfirmerModal
                title="Proceed with adding user"
                body="Adding another user will incur additional costs."
                onConfirm={() =>
                  setModal({
                    show: true,
                    title: "Add user",
                    method: "post",
                  })
                }
              >
                {({ open }) => (
                  <Button variant="primary" onClick={open}>
                    Add user to workspace
                  </Button>
                )}
              </ConfirmerModal>
            ) : (
              <Button
                variant="primary"
                disabled={
                  _.isNumber(numberOfSeatsIncluded) &&
                  workspace.users.length >= numberOfSeatsIncluded
                }
                onClick={() =>
                  setModal({ show: true, title: "Add user", method: "post" })
                }
              >
                Add user to workspace
              </Button>
            )}
            {settings.hasCsvButtons && (
              <>
                <CsvImport
                  schema={[
                    {
                      id: "workspace_id",
                      description: "The ID of the worskpace",
                      example: "123",
                    },
                    {
                      id: "user_id",
                      description: "The email address of the user",
                      example: "user@email.com",
                    },
                    {
                      id: "role",
                      description: "The role for the user to assume",
                      example: "admin",
                    },
                  ]}
                  onSubmit={(formData) =>
                    onSubmit(formData, {
                      method: "post",
                      encType: "multipart/form-data",
                    })
                  }
                >
                  {({ onClick }) => (
                    <Button variant="primary" onClick={onClick}>
                      Import CSV
                    </Button>
                  )}
                </CsvImport>
                <FileDownloader
                  requestConfig={{ url: apiUrl("workspace-users/csv") }}
                  name="workspace-users.csv"
                >
                  {({ onClick, isLoading, error }) => {
                    if (error instanceof StatusError && error.status === 403) {
                      window.location.reload();
                    } else if (error) {
                      console.error(error);
                    }
                    return (
                      <Button
                        variant={_.isUndefined(error) ? "primary" : "danger"}
                        onClick={onClick}
                        disabled={isLoading}
                      >
                        {_.isUndefined(error)
                          ? "Export CSV"
                          : "Failed. Try again!"}
                      </Button>
                    );
                  }}
                </FileDownloader>
              </>
            )}
          </Stack>
        )}
      </div>
      {workspace.users.length === 0 && <Alert variant="info">No users</Alert>}
      {workspace.users.length > 0 && (
        <WorkspaceUsersList
          authedUserId={authedUserId}
          isSubmitting={isSubmitting}
          workspaceUsers={workspace.users}
          onEdit={(user) =>
            setModal({
              show: true,
              title: `Edit ${user.userId}`,
              user,
              method: "patch",
            })
          }
          onDelete={(userId) =>
            onSubmit(
              {
                entity: "workspace-user",
                userId,
                workspaceId: workspace.workspaceId,
              },
              {
                method: "delete",
                encType: "application/json",
              }
            )
          }
          isAdmin={isAdmin}
          mode={mode}
        />
      )}
      <WorkspaceUserModal
        onHide={() => setModal({ show: false })}
        show={modal.show}
        title={modal.title}
        isSubmitting={isSubmitting}
        onSubmit={(values) =>
          onSubmit(
            {
              entity: "workspace-user",
              userId: values.userId,
              workspaceId: workspace.workspaceId,
              role: values.role,
            },
            {
              method: modal.method,
              encType: "application/json",
            }
          )
        }
        workspaceUser={modal.user}
        mode={mode}
        userIds={_.difference(userIds, _.map(workspace.users, "userId"))}
      />
    </>
  );
};
