import _ from "lodash";
import { defer, LoaderFunctionArgs } from "react-router-dom";

import { Users, Workspaces, WorkspaceWithUsers } from "../../../api";
import { ApiMode } from "../../types";

export interface WorkspacesLoaderData {
  workspaces: WorkspaceWithUsers[];
  userIds: string[];
}

export const workspacesLoader =
  (mode: ApiMode) =>
  async ({ request: { signal } }: LoaderFunctionArgs) => {
    const fetch = async (): Promise<WorkspacesLoaderData> => {
      const workspaces = await Workspaces.fetchAndParse({
        mode,
        signal,
      });
      // @TODO: Could move the fetching of users into add-workspace-modal.
      // Admin mode needs list of userIds when creating a workspace.
      const users =
        mode === ApiMode.Admin ? await Users.fetchAllAndParse({ signal }) : [];
      const userIds = _.map(users, "userId");
      return { workspaces, userIds };
    };
    return defer({ workspaces: fetch() });
  };
