import { Form, Formik } from "formik";
import _ from "lodash";
import { ReactNode } from "react";
import { Button, Col, Form as BsForm, Row } from "react-bootstrap";

import { UserProject, WorkspaceUserRole } from "../../../api";
import { FormItem } from "../../../common/components/form-item";
import { TextArrayField } from "../text-array-field";
import { UserProjectsField } from "../user-projects-field";
import { UserWorkspacesField, WorkspaceOption } from "../user-workspaces-field";
import { userFieldsSchema } from "./user-fields-schema";

interface Workspace {
  workspaceId: string;
  role: WorkspaceUserRole;
}

interface Input {
  name: string;
  username: string;
  workspaces: Workspace[];
  userId: string;
  groups: string[];
  projects: UserProject[];
}

export interface UserFormSubmissionWithoutWorkspaces {
  userId: string;
  groups: string[];
  projects: UserProject[];
}

export interface UserFormSubmissionWithWorkspaces
  extends UserFormSubmissionWithoutWorkspaces {
  workspaces: Workspace[];
}

type Output<T extends boolean> = T extends true
  ? UserFormSubmissionWithWorkspaces
  : UserFormSubmissionWithoutWorkspaces;

interface UserFormProps<T extends boolean> {
  onSubmit: (values: Output<T>) => void;
  isSubmitting?: boolean;
  user?: Partial<Input>;
  children: ({
    fields,
    submit,
  }: {
    fields: ReactNode;
    submit: ReactNode;
  }) => ReactNode;
  submitButtonText?: string;
  isSubmitEnabledWithoutChange?: boolean;
  includeWorkspaces?: T;
  workspaceOptions?: WorkspaceOption[];
}

const inputDefaults: Input = {
  groups: [],
  name: "",
  projects: [],
  userId: "",
  username: "",
  workspaces: [],
};

export const UserForm = <T extends boolean>({
  onSubmit,
  user,
  isSubmitting = false,
  submitButtonText = "Ok",
  isSubmitEnabledWithoutChange = false,
  includeWorkspaces = false as T,
  workspaceOptions = [],
  children,
}: UserFormProps<T>) => {
  const isExistingUser = !_.isUndefined(user);
  const initialValues = _.defaults(
    {},
    includeWorkspaces ? user : _.omit(user, "workspaces"),
    includeWorkspaces ? inputDefaults : _.omit(inputDefaults, "workspaces")
  );
  return (
    <Formik
      validationSchema={userFieldsSchema}
      onSubmit={(values) =>
        onSubmit(
          _.pick(values, [
            "groups",
            "projects",
            "userId",
            "workspaces", // If workspaces isn't in values then this entry has no effect on output.
          ]) as Output<T>
        )
      }
      initialValues={initialValues}
    >
      {({ values, submitForm }) => (
        <Form method="post" noValidate>
          <fieldset disabled={isSubmitting}>
            {children({
              fields: (
                <>
                  <Row>
                    <Col lg="6">
                      <FormItem
                        className="mb-3"
                        name="userId"
                        label="ID"
                        type="text"
                        placeholder="email"
                        readOnly={isExistingUser}
                        as={BsForm.Control}
                      />
                    </Col>
                    {isExistingUser && (
                      <>
                        <Col lg="6">
                          <FormItem
                            className="mb-3"
                            name="username"
                            label="Username"
                            type="text"
                            readOnly
                            as={BsForm.Control}
                          />
                        </Col>
                        <Col lg="6">
                          <FormItem
                            className="mb-3"
                            name="name"
                            label="Name"
                            type="text"
                            readOnly
                            as={BsForm.Control}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col lg="6" className="pb-3">
                      <UserProjectsField />
                    </Col>
                    <Col lg="6" className="pb-3">
                      <TextArrayField
                        title="Groups"
                        name="groups"
                        label="Group"
                      />
                    </Col>
                    {includeWorkspaces && (
                      <Col lg="6" className="pb-3">
                        <UserWorkspacesField options={workspaceOptions} />
                      </Col>
                    )}
                  </Row>
                </>
              ),
              submit: (
                <Button
                  variant="primary"
                  onClick={submitForm}
                  disabled={
                    !isSubmitEnabledWithoutChange &&
                    _.isEqual(values, initialValues)
                  }
                >
                  {submitButtonText}
                </Button>
              ),
            })}
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
