import _ from "lodash";

import { Table } from "../../../common/components/table";
import { ResourceId } from "../../constants";
import intervals from "../../intervals";
import { ActivityData, PlainTextTableData } from "../../types";
import { DataControlsValue } from "../../types";
import {
  aggregateActivityDataByInterval,
  extractUsageData,
} from "../../utils/data";
import { getGroupsFromData } from "../../utils/data";
import { ActivityExports } from "../activity-exports";
import { ActivitySection } from "../activity-section";
import { Chart } from "../chart";
import { generateChartData } from "../chart/generate-chart-data";
import { ResourceUsage } from "../resource-usage";
import { TrendValue } from "../trend-value";
import { generateUsageBreakdownTableData } from "./generate-usage-breakdown-table-data";

interface UsageProps {
  activity: ActivityData[];
  isLoading?: boolean;
  dataControlsValue: DataControlsValue;
}

export const Usage = ({
  activity,
  dataControlsValue,
  isLoading = false,
}: UsageProps) => {
  const { intervalId, grouping, isAdminMode } = dataControlsValue;
  const resourceUtilisationData = extractUsageData(activity);
  return (
    <>
      <ActivitySection title="Resource Utilisation">
        <div className="d-flex justify-content-between align-items-start">
          <p>
            Cost-weighted average of cpuEfficiency and ramEfficiency. In
            equation form: ((cpuEfficiency * cpuCost) + (ramEfficiency *
            ramCost)) / (cpuCost + ramCost)
          </p>{" "}
          <TrendValue
            data={_.map(
              aggregateActivityDataByInterval(resourceUtilisationData),
              "efficiency"
            )}
          />
        </div>
        <Chart
          isLoading={isLoading}
          data={generateChartData({
            data: resourceUtilisationData,
            groups: getGroupsFromData(grouping, resourceUtilisationData),
            grouping,
            aggregator: (items) => {
              const legit = _.filter(items, (item) =>
                _.isNumber(item.efficiency)
              );
              return (
                _.sum(
                  _.map(
                    legit,
                    ({ efficiency, cost }) => (efficiency as number) * cost
                  )
                ) / _.sum(_.map(legit, "cost"))
              );
            },
          })}
          datetimeFormat={intervals[intervalId].format}
          grouping={grouping}
          yFormatter={(value) => `${_.round(value * 100, 2)}%`}
          options={{
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
                ticks: {
                  /* eslint-disable-next-line */
                  callback: (value: any) => `${_.round(value * 100, 2)}`,
                },
                title: {
                  display: true,
                  text: "% efficiency",
                },
              },
            },
          }}
        />
      </ActivitySection>
      <ResourceUsage
        title="CPU Usage"
        description="Cumulative CPU core-hours allocated."
        activity={activity}
        isLoading={false}
        dataControlsValue={dataControlsValue}
        yFormatter={(value) => `${_.round(value, 2)}`}
        yLabel="Core hours"
        resourceId={ResourceId.Cpu}
      />
      <ResourceUsage
        title="RAM Usage"
        description="Cumulative RAM GB-hours allocated."
        activity={activity}
        isLoading={false}
        dataControlsValue={dataControlsValue}
        yFormatter={(value) => `${_.round(value / 10 ** 9, 3)}`}
        yLabel="GB hours"
        resourceId={ResourceId.Ram}
      />
      <ResourceUsage
        title="GPU Usage"
        description="Cumulative GPU-hours allocated."
        activity={activity}
        isLoading={false}
        dataControlsValue={dataControlsValue}
        yFormatter={(value) => `${_.round(value, 2)}`}
        yLabel="hours"
        resourceId={ResourceId.Gpu}
      />
      <ActivitySection title="Breakdown">
        <Table
          bordered
          {...generateUsageBreakdownTableData(activity)}
          setTdClassName={({ isLastRow }) => [{ "fw-bold": isLastRow }]}
        />
      </ActivitySection>
      <ActivityExports
        csv={{
          name: "usage",
          contents: generateUsageBreakdownTableData(activity, {
            asPlainText: true,
          }) as PlainTextTableData,
        }}
        isAdminMode={isAdminMode}
      />
      <ActivitySection title="Reference">
        Descriptions sourced from{" "}
        <a
          target="_blank"
          href="https://docs.kubecost.com/apis/apis-overview/api-allocation"
          rel="noreferrer"
        >
          https://docs.kubecost.com/apis/apis-overview/api-allocation
        </a>
      </ActivitySection>
    </>
  );
};
