import React from "react";
import { Await, useLoaderData } from "react-router-dom";

import { Spinner } from "../../../common/components/spinner";
import { TabComponentMode } from "../../types";
import { useWorkspaces } from "./use-workspaces";
import { Workspaces } from "./workspaces.component";
import { WorkspacesLoaderData } from "./workspaces-loader";

interface ConnectedWorkspacesProps {
  mode: TabComponentMode;
}

export interface WorskpaceLoader {
  workspaces: Promise<WorkspacesLoaderData>;
}

export const ConnectedWorkspaces = ({ mode }: ConnectedWorkspacesProps) => {
  const data = useLoaderData() as WorskpaceLoader;
  const { isSubmitting, onSubmit } = useWorkspaces();
  return (
    <React.Suspense fallback={<Spinner />}>
      <Await resolve={data.workspaces}>
        {({ workspaces, userIds }: WorkspacesLoaderData) => (
          <Workspaces
            workspaces={workspaces}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            mode={mode}
            userIds={userIds}
          />
        )}
      </Await>
    </React.Suspense>
  );
};
