import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, FieldArray, useField } from "formik";
import _ from "lodash";
import { Stack } from "react-bootstrap";

interface TextArrayFieldProps {
  name: string;
  title: string;
  label: string;
}

export const TextArrayField = ({ name, title, label }: TextArrayFieldProps) => {
  const meta = useField(name)[1];
  return (
    <>
      <label className="form-label">{title}</label>
      <div className="border p-3">
        <FieldArray name={name}>
          {({ remove, push }) => (
            <div>
              {meta.value.map((_item: unknown, index: number) => (
                <div className="mb-3" key={index}>
                  <Stack direction="horizontal" gap={3}>
                    <div className="w-100">
                      <label
                        className="visually-hidden"
                        htmlFor={`${name}.${index}`}
                      >
                        {label}
                      </label>
                      <Field
                        name={`${name}.${index}`}
                        placeholder={label}
                        type="text"
                        className="form-control form-control-sm py-0"
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => remove(index)}
                      aria-label={`Remove ${label}`}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Stack>
                  <ErrorMessage
                    name={`${name}.${index}`}
                    component="div"
                    className="d-block invalid-feedback"
                  />
                </div>
              ))}{" "}
              {_.isString(meta.error) && (
                <ErrorMessage
                  name={`${name}`}
                  component="div"
                  className="d-block invalid-feedback mb-3"
                />
              )}
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => push("")}
              >
                Add
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </>
  );
};
