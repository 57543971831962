import "./date-range-input.scss";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { DayPicker } from "react-day-picker";

import { Dropdown } from "../dropdown";

const dateFormat = "dd MMM yy";

type DateRange = [string, string];

interface DateRangeInputProps {
  /**
   * DateRangeInput value as array with first entry being start date and last being end date.
   */
  value: DateRange;
  /**
   * Change handler that receives same format as value prop.
   */
  onChange: (value: DateRange) => void;
  /**
   * Optional minimum date that can be selected.
   */
  min?: string;
  /**
   * Optional maximum date that can be selected.
   */
  max?: string;
  /**
   * Flag to disable the input.
   */
  disabled?: boolean;
  /**
   * Optional label override, defaults to "Date".
   */
  label?: string;
}

/**
 * Controlled input for selecting a date range.
 */
export const DateRangeInput = ({
  value,
  onChange,
  min,
  max,
  label = "Date",
  disabled = false,
}: DateRangeInputProps) => {
  const [draft, setDraft] = useState(value);
  const [start, end] = draft;
  return (
    <Dropdown
      label={label}
      text={`${DateTime.fromISO(start).toFormat(
        dateFormat
      )} - ${DateTime.fromISO(end).toFormat(dateFormat)}`}
      icon={faCalendar}
      disabled={disabled}
      id="date-range"
      onExited={() => onChange(draft)}
    >
      <DayPicker
        className="easi-day-picker p-3"
        disabled={disabled}
        mode="range"
        selected={{
          from: DateTime.fromISO(start).toJSDate(),
          to: DateTime.fromISO(end).toJSDate(),
        }}
        onSelect={(value) => {
          if (_.isUndefined(value) || _.isUndefined(value.from)) {
            return;
          }
          setDraft([
            DateTime.fromJSDate(value.from).toISODate() as string,
            DateTime.fromJSDate(
              _.isUndefined(value.to) ? value.from : value.to
            ).toISODate() as string,
          ]);
        }}
        hidden={(date) => {
          if (max && date > DateTime.fromISO(max).toJSDate()) {
            return true;
          }
          if (min && date < DateTime.fromISO(min).toJSDate()) {
            return true;
          }
          return false;
        }}
        defaultMonth={DateTime.fromISO(start).toJSDate()}
      />
    </Dropdown>
  );
};
