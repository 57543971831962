import { decodeJWT } from "@aws-amplify/auth";
import _ from "lodash";

interface IdTokenPayload {
  sub: string; // JWT subject.
  "cognito:username": string;
  "cognito:groups": string[];
  email: string;
  email_verified: boolean;
  exp: number; // Expires.
  iat: number; // issued at.
  jti: string; // JWT ID.
  origin_jti: string;
  auth_time: number;
  aud: string; // Audience.
  token_use: "id";
  iss: string; // Issuer.
}

interface FederatedIdTokenPayload extends IdTokenPayload {
  at_hash: string;
  nonce: string;
  identities: {
    userId: string;
    providerName: string;
    providerType: string;
    issuer: string;
    primary: string;
    dateCreated: string;
  }[];
}

export const isFederatedIdTokenPayload = (
  payload: unknown
): payload is FederatedIdTokenPayload => _.has(payload, "identities");

export const decodeIdToken = (token: string): IdTokenPayload =>
  decodeJWT(token).payload as unknown as IdTokenPayload;
