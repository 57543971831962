import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { DateTime, Interval } from "luxon";
import { ReactNode, useMemo } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import attributes from "../../attributes";
import { TIMEZONE_DISPLAY } from "../../constants";
import { uiIntervals } from "../../intervals";
import { AttributeOptions, DataControlsValue } from "../../types";
import { AttributeSelector } from "../attribute-selector";
import { ButtonSelect } from "../button-select";
import { DateRangeInput } from "../date-range-input";

interface DataControlsProps {
  value: DataControlsValue;
  onChange: (value: DataControlsValue) => void;
  attributeOptions: AttributeOptions;
  children?: ReactNode;
  showAdminModeSwitch?: boolean;
}

export const DataControls = ({
  value,
  onChange,
  attributeOptions,
  children,
  showAdminModeSwitch = false,
}: DataControlsProps) => {
  const { datetimeRange, intervalId, grouping, selectedAttributes } = value;
  const duration = Interval.fromDateTimes(
    DateTime.fromISO(datetimeRange[0]),
    DateTime.fromISO(datetimeRange[1])
  ).toDuration();
  const dateRange: [string, string] = useMemo(
    () => [
      DateTime.fromISO(datetimeRange[0], {
        zone: TIMEZONE_DISPLAY,
      }).toISODate() as string,
      DateTime.fromISO(datetimeRange[1], {
        zone: TIMEZONE_DISPLAY,
      }).toISODate() as string,
    ],
    [datetimeRange]
  );
  return (
    <>
      {showAdminModeSwitch ? (
        <div className="d-flex flex-row-reverse justify-content-between">
          <div className="d-flex align-items-center">
            <Form.Switch
              label={<span className="fs-7 fw-bold">Admin mode</span>}
              aria-label="Admin mode"
              checked={value.isAdminMode}
              onChange={() =>
                onChange({
                  ...value,
                  isAdminMode: !value.isAdminMode,
                })
              }
            />
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  Enabling admin mode will include all workspaces, not just ones
                  you are a member of.
                </Tooltip>
              )}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ms-2 text-primary"
              />
            </OverlayTrigger>
          </div>
          {children}
        </div>
      ) : (
        children
      )}
      <Row className="justify-content-start">
        <Col sm="12" md="6" lg="4">
          <DateRangeInput
            value={dateRange}
            max={DateTime.now().setZone(TIMEZONE_DISPLAY).toISODate() as string}
            onChange={(dateRange) =>
              onChange({
                ...value,
                datetimeRange: [
                  DateTime.fromISO(dateRange[0], { zone: TIMEZONE_DISPLAY })
                    .startOf("day")
                    .toISO() as string,
                  DateTime.fromISO(dateRange[1], { zone: TIMEZONE_DISPLAY })
                    .endOf("day")
                    .toISO() as string,
                ],
              })
            }
          />
        </Col>
        <Col md="12" lg="auto" className="flex-grow-1">
          <Row>
            {_.map(attributes, (attribute) => (
              <Col sm="4" key={attribute.id}>
                <AttributeSelector
                  attribute={attribute}
                  value={_.get(selectedAttributes[attribute.id], "id")}
                  onChange={(selected) =>
                    onChange({
                      ...value,
                      selectedAttributes: {
                        [attribute.id]: _.get(
                          _.find(attributeOptions[attribute.id], {
                            value: { id: selected },
                          }),
                          "value"
                        ),
                      },
                    })
                  }
                  options={_.map(attributeOptions[attribute.id], (option) => ({
                    label: option.value.label,
                    value: option.value.id,
                    disabled: option.disabled,
                  }))}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <ButtonSelect
        label="Interval"
        value={intervalId}
        options={_.map(uiIntervals, ({ id, diurnal, isDisabled }) => ({
          value: id,
          label: _.startCase(diurnal),
          disabled: isDisabled(duration),
        }))}
        onChange={(selected) =>
          onChange({
            ...value,
            intervalId: selected,
          })
        }
        className="mb-3"
      />
      <ButtonSelect
        label="Stack"
        value={grouping || ""}
        options={[
          { value: "", label: "None" },
          ..._.map(attributes, (item) => ({
            value: item.id,
            label: _.startCase(item.singular),
          })),
        ]}
        onChange={(selected) =>
          onChange({
            ...value,
            grouping: selected === "" ? undefined : selected,
          })
        }
        className="mb-3"
      />
    </>
  );
};
