import _ from "lodash";
import { DateTime } from "luxon";

import { ToastArgs, ToastType } from "./toasts.component";

export const createToast = (args: ToastArgs): ToastType => ({
  ...args,
  show: true,
  datetime: DateTime.now().toISO(),
});

export const createErrorToast = (error: Error) =>
  createToast({
    header: _.get(error, "response.statusText", error.name),
    body: _.get(error, "response.data.message", error.message),
    type: "danger",
  });
